const Browse_category_data = [
	{
		id: '0French Test',
		image: 'https://ik.imagekit.io/m73nvqjgwy/test%20de%20niveau%20de%20fran%C3%A7ais/9ef4751a-3949-4f10-9117-ea7e76076c8c.webp?updatedAt=1704630215457',
		title: 'French Test',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(70 199 227)',
		path:'/theme-quiz',

	},
	{
		id: '1Verbal Modes',
		image: 'https://ik.imagekit.io/m73nvqjgwy/test%20de%20niveau%20de%20fran%C3%A7ais/intro.webp?updatedAt=1701253871949',
		title: 'Verbal Modes',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(115 126 242)',
		path:'/theme-quiz',

	},
	{
		id: '2Translation',
		image: 'https://ik.imagekit.io/m73nvqjgwy/test%20de%20niveau%20de%20fran%C3%A7ais/df4d6e89-2e08-4044-8e04-9046a13677d4_R_Y1PeHLT.webp?updatedAt=1701255295759',
		title: 'Translation & Understanding',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(66 138 248)',
		path:'/theme-quiz',

	},
	{
		id: '3Means of transportation',
		image: 'https://ik.imagekit.io/m73nvqjgwy/voyage/f7c0a761-b343-476f-bb17-76b29c296bf0.webp?updatedAt=1704575387515',
		title: 'Means of transportation',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(243 91 199)',
		path:'/theme-quiz',

	},
	{
		id: '4Travel',
		image: 'https://ik.imagekit.io/m73nvqjgwy/voyage/7faa545a-edaa-4402-ba51-f984872c2f92.webp?updatedAt=1704575136296',
		title: 'Travel',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(16 185 129)',
		path:'/theme-quiz',

	},
	{
		id: '5Cities of the world',
		image: 'https://ik.imagekit.io/m73nvqjgwy/villes/340e205e-0cbd-49ed-ae62-25b97b710534.webp?updatedAt=1705854991192',
		title: 'Cities of the world',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(254 178 64)',
		path:'/theme-quiz',

	},
	{
		id: '6Flags of the World',
		image: 'https://ik.imagekit.io/m73nvqjgwy/flags/d3851e64-8ee9-4f9c-b513-3c475850e971.webp?updatedAt=1708800416560',
		title: 'Flags of the World',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(131 88 255)',
		path:'/theme-quiz',

	},
	{
		id: '7Gastronomy',
		image: 'https://ik.imagekit.io/m73nvqjgwy/cuisine/4aa72558-6b30-40bf-a970-f8688c45a9c7.webp?updatedAt=1704576375183',
		title: 'Gastronomy',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(70 199 227)',
		path:'/theme-quiz',

	},
	{
		id: '8Museum',
		image: 'https://ik.imagekit.io/m73nvqjgwy/monument%20/b4768e29-ac8c-442a-b1ad-c2d8e1d8882f.webp?updatedAt=1704583080356',
		title: 'Museum',
		href: 'https://www.my-french.app/',
		bgColor: '#737EF2',
		path:'/theme-quiz',

	},
	{
		id: '9Astronomy',
		image: 'https://ik.imagekit.io/m73nvqjgwy/astronomie/6d7b0e12-f9c5-42a2-b7df-403e5e494f73.webp?updatedAt=1704547351522',
		title: 'Astronomy',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(66 138 248)',
		path:'/theme-quiz',

	},
	{
		id: '10Halloween',
		image: 'https://ik.imagekit.io/m73nvqjgwy/Halloween/01f99052-0065-45c7-ad5b-0b51bb428afc.webp?updatedAt=1704322552144',
		title: 'Halloween',
		href: 'https://www.my-french.app/',
		bgColor: '#F35BC7',
		path:'/theme-quiz',

	},
	{
		id: '11Christmas',
		image: 'https://ik.imagekit.io/m73nvqjgwy/noel/8378815f-cab1-4654-98cc-8af4ce9a6968.webp?updatedAt=1704322085872',
		title: 'Christmas',
		href: 'https://www.my-french.app/',
		bgColor: 'rgb(16 185 129)',
		path:'/theme-quiz',

	},
	{
		id: '12Valentine\'s Day',
		image: 'https://ik.imagekit.io/m73nvqjgwy/test%20de%20niveau%20de%20fran%C3%A7ais/e2905422-8334-4b4f-a84a-151dbe16d801.webp?updatedAt=1708204107927',
		title: 'Valentine\'s Day',
		href: 'https://www.my-french.app/',
		bgColor: '#46C7E3',
		path:'/theme-quiz',

	},
];

export default Browse_category_data;
