import Link from "next/link";
import React from "react";

const hero = () => {
  return (
    <section className="relative pb-10 pt-20 md:pt-32 h-1527">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
        <img
          src="/images/gradient.jpg"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img
          src="/images/gradient_dark.jpg"
          alt="gradient dark"
          className="h-full w-full"
        />
      </picture>

      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div
              className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4">
            <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-7xl">Learn French Easily.
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left">
              My French Makes Me Learn In Fun And Effective Way ! Gain Points, Get Through Levels, And Enhance Your Skills To Shine Everyday.
            </p>
              <div className="flex space-x-4">

                  <a
                      href="https://apps.apple.com/us/app/my-french/id6474870559"
                      className="group flex items-center rounded-full bg-white py-3 px-6 text-center font-semibold text-jacarta-500 shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                  >
                      <svg
                          className="icon mr-2 -mt-1 inline-block h-5 w-5 fill-jacarta-500 transition-colors group-hover:fill-white">
                          <use xlinkHref="/icons.svg#icon-apple"></use>
                      </svg>
                      App Store
                  </a>
                  <a
                      href="https://play.google.com/store/apps/details?id=com.ae.frenchlearn&gl=FR"
                      className="flex items-center rounded-full bg-white py-3 px-6 text-center font-semibold text-jacarta-500 shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                  >
                      <img
                          src="/images/playStore.png"
                          alt=""
                          className="mr-2 h-5 w-5 fill-jacarta-700 dark:fill-white"
                      />
                      Google play
                  </a>
              </div>
          </div>

            {/* <!-- Hero image --> */}
            <div className="col-span-6 xl:col-span-8">
                <div className="relative text-center md:pl-8 md:text-right">
                    <img
                        src="/images/hero/hero.jpg"
                        alt=""
                        className="hero-img mt-8 inline-block w-72 rotate-[8deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
                    />
                    <img
                        src="/images/hero/3D_elements.png"
                  alt=""
                  className="animate-fly absolute top-0 md:-right-[10%]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero;
